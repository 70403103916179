.mailbox-user-top__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  background: var(--light-main-color2);
}

.malibox-user-element--bold {
  font-weight: 700;
}

.mailbox-user-top__text {
  color: var(--main-color);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 100px; /* 500% */
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.malibox-user-element__date {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.malibox-user-element__icon {
  width: 20px;
}


.mailbox-user-top__return-button {
  color: var(--white-color);
  position: fixed;
  top: 10px;
  left: 15px;
  z-index: 100;
} 

.malibox-user-element__wrapper {
  height: 90vh;
  overflow: auto;
  background: var(--light-main-color2);
}

.malibox-user-element {
  padding: 20px 10px;
  border-bottom: 1px solid var(--light-main-color);
  background: var(--lighter-main-color);
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  color: var(--main-color);
}
.malibox-user-element:hover {
  border-bottom: 1px solid var(--light-main-color);
  background: var(--light-main-color);
  cursor: pointer;
  color: white !important;
}

.mailbox-user-top__text_text {
  color: var(--main-color);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mailbox-button__back {
  width: 30px;
}

.mailbox-user-selected__element__top {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
  border-bottom: 1px solid var(--light-main-color2);
}

.malibox-user-selected__element__wrapper {
  background: var(--lighter-main-color);
  height: 90vh;
  position: relative;
  border-radius: 8px;
}

.mailbox-user-name {
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mailbox-user-name-message {
  max-width: 180px;
  min-width: 180px;
}
.mailbox-user-wrapper__name__message {
  margin: 10px 0;
}

.mailbox-user-wrapper__messages {
  height: 70vh;
  overflow: auto;
}

.mailbox-user-profile {
  width: 30px;
}

.mailbox-user-answer-button__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.mailbox-user-answer-button__wrapper__answer-mobile {
  background-color: white;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mailbox-user-answer-button__wrapper__answer-mobile > textarea {
  border: none;
  resize: none;
  width: 90%;
  margin: 10px;

  color: var(--main-color);
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
}

.mailbox-user-answer-button__wrapper__answer-mobile > textarea:hover {
  outline: none;
}

.mailbox-user-answer-button {
  display: inline-flex;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--main-color);
  cursor: pointer;
  color: var(--secondary-color);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  border: none;
}

.mailbox-user-wrapper {
  background: var(--light-main-color2);
  height: 100vh;
}

.mailbox-user-wrapper-element_message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0 20px;

  color: var(--main-color);
  border-bottom: 1px solid var(--light-main-color2);
  background: var(--lighter-main-color);
  height: 60px;
}
.mailbox-user-helper {
  display: none;
}

.mailbox-user-answer-button__wrapper__answer-desktop {
  display: none;
}

.malibox-user-element__top_head_wrapper {
  display: none;
}


.malibox-user-element__top_head_wrapper {
  padding: 0 10px;
  display: flex;
  gap: 30px;
  justify-content: left;
  align-items: center;
  color: #fff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 40px;
  background: var(--main-color);
  border-radius: 8px 8px 0 0;
}

@media (min-width: 600px) {


.malibox-user-element__top_head_wrapper {
  margin: 0 20px;}
  .mailbox-user-wrapper__messages {
    margin: 0 20px;
  }
  .mailbox-user-top__text {
    font-size: 32px;
  }

  .mailbox-user-top__text_text {
    font-size: 28px;
  }

  .malibox-user-selected__element__wrapper {
    margin: 20px;
    height: calc(90vh - 40px);
  }

  .mailbox-user-selected__element__top {
    display: flex;
    margin: 0 20px;
    justify-content: space-between;
  }

  .mailbox-user-helper {
    display: flex;
    width: 30px;
  }

  .mailbox-user-name {
    max-width: 10vw;
    min-width: 10vw;
    font-size: 16px;
  }

  .mailbox-user-wrapper__name__message {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .mailbox-user-name-message {
    max-width: 70vw;
    min-width: 70vw;
    font-size: 16px;
  }

  .mailbox-user-answer-button__wrapper {
    margin-top: -20px;
  }

  .mailbox-user-answer-button__wrapper__answer-mobile {
    display: none;
  }

  .mailbox-user-answer-button__wrapper__answer-desktop {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 30px;
  }

  .mailbox-user-answer-button__wrapper__answer-desktop > textarea {
    resize: none;
    border: none;
    border-radius: 0px 8px 0px 0px;
    background: #fff;
    width: 100%;
    padding: 30px;
    font-size: 16px;

    color: var(--main-color);
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .malibox-user-element__wrapper {
    margin: 0 20px 20px 20px;
    height: 80vh;
    border-radius: 0 0 8px 8px;
  }

  .malibox-user-element__text {
    display: flex;
    gap: 30px;
  }

  .malibox-user-element {
    justify-content: left;
  }

}

textarea:hover,
textarea:focus {
  outline: none;
}
