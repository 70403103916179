.your-activity-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(85, 159, 168, .7);
    z-index: 1000;
    display: none;
}

.your-activity-modal.your-activity-modal--active {
    display: block;
}

.your-activity-modal__closing-div {
    width: 100%;
    height: 100%;

}

.your-activity-modal__activity-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}


.your-activity-modal__closing-button {
    font-size: 2.4rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--main-color);
    display: block;
    margin-left: auto;
    color: #fff;
    
    position: absolute;
    top: 5px;
    right: 10px;

}

@media (max-width: 768px) {
    .your-activity-modal__activity-box {
        width: 90%;
    }

    .your-activity-modal__closing-button {       
        right: calc(50% - 165px);
    
    }
    
}


@media (max-width: 380px) {

    .your-activity-modal__closing-button {       
        right: 10px;
    
    }
    
}