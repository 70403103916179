.register-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh;
    background-color: rgba(85, 159, 168, 0.3);
    backdrop-filter: blur(2px);
    z-index: 101;
    display: none;
}

.register-modal.register-modal--active {
    display: block;
}

.register-modal-box {
    position: fixed;
    top: 50%;
    left: 50%;
    max-height: 100%;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    background-color: var(--alternative-main-color);
    border-radius: 7px;
    max-height: 90vh;
}


.register-modal__form {
    width: fit-content;
    max-width: 450px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 20px 20px 20px;
    border-radius: 5px;
  }

  .register-modal__buttons-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 10px 0 10px;
  }
  
  .register-modal__closing-button {
    background-color: transparent;
    border: none;
    font-size: 1.8rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    border: 2px solid #fff;
    cursor: pointer;
} 

.register-modal__info-button {
    background-color: transparent;
    border: none;
    font-size: 1.8rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--white-color);
    border: 2px solid var(--white-color);
    cursor: pointer;
}

  .register-modal__form-title {
    font-size: 4rem;
    font-weight: 700;
    padding-bottom: 20px;
    text-transform: uppercase;
    color: var(--white-color);
    font-family: 'roboto';
  }

  .register-modal__form-slogan {
    text-align: center;
    line-height: 120%;
    color: var(--white-color);
    margin-bottom: 30px;
  }
  
  .register-modal__input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
  }
  
  .register-modal__input-label {
    color: var(--white-color);
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 10px;
  }
  
  .register-modal__input {
    font-size: 1.6rem;
    padding: 10px 5px;
    border: none;
    background-color: var(--white-color);
    border-radius: 5px;
    width: 100%;
    height: 40px;
  }
  
  .register-modal__input-desc {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .register-modal__input:focus {
    outline: none;
  }
  
  .register-modal__alert {
    margin-top: 10px;
    color: #f00;
    font-size: 1.6rem;
    font-weight: 600;
  }
  
  .register-modal__password-wrapper {
    position: relative;
    width: 100%;
  }
  
  .register-modal__password-icon-button {
    border: none;
    background: transparent;
    font-size: 2rem;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--main-color)
  }

  .register-modal__info-text-wrapper {
    position: relative;
  }

  .register-modal__info-button:hover .register-modal__info-text{
    display: block;
}

.register-modal__info-text {
  display: none;
  position: absolute;
  left: 50%;
  top: 55px;
  z-index: 99;
  transform: translateY(-50%);
  right: 100%;
  margin-right: 15px;
  background-color: var(--white-color);
  width: 300px;
  max-width: 80vw;
  padding: 10px 20px;
  border-radius: 5px;
}

.register-modal__info-text {
    font-family: 'Roboto';
    text-transform: none;
    color: #000;
}

.register-modal__span-block {
    display: block;
}


@media (max-width: 600px) {
    .register-modal-box {
        width: 98%;
    }
}
